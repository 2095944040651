<template>


  <div>
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog as="div" class="fixed inset-0 flex z-40 md:hidden" @close="sidebarOpen = false">
        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0"
                         enter-to="opacity-100" leave="transition-opacity ease-linear duration-300"
                         leave-from="opacity-100"
                         leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75"/>
        </TransitionChild>
        <TransitionChild as="template" enter="transition ease-in-out duration-300 transform"
                         enter-from="-translate-x-full" enter-to="translate-x-0"
                         leave="transition ease-in-out duration-300 transform"
                         leave-from="translate-x-0" leave-to="-translate-x-full">
          <div class="relative flex-1 flex flex-col max-w-xs w-full pb-4 bg-primary-500">
            <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0"
                             enter-to="opacity-100"
                             leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button type="button"
                        class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        @click="sidebarOpen = false">
                  <span class="sr-only">Close sidebar</span>
                  <font-awesome-icon :icon="['far', 'xmark']" class="text-primary-500" size="lg"></font-awesome-icon>
                </button>
              </div>
            </TransitionChild>
            <div class="flex items-center h-16 flex-shrink-0 px-4 bg-gray-200">
              <img class="h-input-box w-auto" :src="logoUrl" alt="Logo"/>
            </div>
            <div class="flex-1 h-0 overflow-y-auto">
              <Navigation :navigation="navigation" v-if="!loading" @closeSidePanel="sidebarOpen = false"/>
            </div>
          </div>
        </TransitionChild>
        <div class="flex-shrink-0 w-14" aria-hidden="true">
          <!-- Dummy element to force sidebar to shrink to fit close icon -->
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex-1 flex flex-col min-h-0 bg-primary-500">
        <div class="flex items-center h-16 flex-shrink-0 px-4 bg-gray-200">
          <router-link :to="{ name: 'dashboard' }">
            <img class="h-input-box w-auto" :src="logoUrl" alt="Logo"/>
          </router-link>
        </div>
        <div class="flex-1 flex flex-col overflow-y-auto">
          <Navigation :navigation="navigation" v-if="!loading"/>
        </div>
      </div>
    </div>
    <div class="md:pl-64 flex flex-col z-50">
      <div class="sticky top-0 z-20 flex-shrink-0 flex h-16 bg-white shadow">
        <button type="button"
                class="px-4 border-r border-gray-200 text-primary-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-200 md:hidden"
                @click="sidebarOpen = true">
          <span class="sr-only">Open sidebar</span>
          <font-awesome-icon :icon="['fas', 'bars']" class="h-6 w-6" size="lg" aria-hidden="true"></font-awesome-icon>
        </button>
        <div class="flex-1 px-4 flex justify-between content-center">
          <!-- <div class="flex-1 flex pt-1.5"></div> -->
          <div class="flex flex-1 items-center justify-end z-50">
            <has-permission role="superadmin">
              <ProviderChange class="z-50"/>
            </has-permission>
            <Menu as="div" class="ml-3 relative">
              <div>
                <MenuButton
                    class="max-w-xs bg-white flex items-center text-sm rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                  <span class="sr-only">Open user menu</span>
                  <span class="w-8 h-8">
                    <font-awesome-icon :icon="['far', 'user']" class="h-8 w-8 rounded-full"
                                       size="lg"></font-awesome-icon>
                  </span>
                </MenuButton>
              </div>
              <transition enter-active-class="transition ease-out duration-100"
                          enter-from-class="transform opacity-0 scale-95"
                          enter-to-class="transform opacity-100 scale-100"
                          leave-active-class="transition ease-in duration-75"
                          leave-from-class="transform opacity-100 scale-100"
                          leave-to-class="transform opacity-0 scale-95">
                <MenuItems
                    class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-0 focus:outline-none">
                  <MenuItem v-for="item in userNavigation" :key="item.name">
                    <div>
                      <router-link active-class="bg-gray-100" :to="{ name: item.routeName }"
                                   class="block px-4 py-2 text-sm text-gray-700">
                        {{ item.name }}
                      </router-link>
                    </div>
                  </MenuItem>
                  <MenuItem v-if="username && userRoleLabel">
                    <div class="block px-4 py-2 text-xs text-gray-700">
                      {{ username }}
                    </div>
                  </MenuItem>
                  <div class="block px-4 py-2 text-xs text-gray-700">
                    {{ versionNumber }}
                  </div>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
      </div>

      <main class="flex-1">
        <!--          <div class="bg-danger-light h-[50px] w-full z-50 fixed"></div>-->
        <div class="default-wrapper mx-auto p-4 sm:p-6 md:p-8 relative">
          <IbiolaLoadingSpinner v-if="loading"/>
          <router-view v-else></router-view>
        </div>
      </main>
    </div>
  </div>
</template>

<script setup>
import {onMounted, onBeforeMount, ref, onUnmounted, computed} from "vue";
import {useI18n} from "vue-i18n";
import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

import useBreakpoints from "@/composables/Layout/useBreakpoints";
import useUser from "@/composables/User/useUser";
import useProvider from "@/composables/Provider/useProvider";
import {useStore} from "vuex";
import useValues from "@/composables/Provider/useValues";
import pkg from '../../package.json';


import IbiolaLoadingSpinner from "@/components/Layouts/LoadingSpinner";

import Navigation from "./Navigation";

import ProviderChange from "@/components/TRNC/ProviderChange";
import {useRoute} from "vue-router";
import useInstantPayments from "@/composables/Accounting/useInstantPayments";
import useAdditionalFee from "@/composables/Accounting/useAdditionalFee";
import useAlerts from "@/composables/Alerts/useAlerts";


const sidebarOpen = ref(false);
const {t} = useI18n();
const {isMobileView} = useBreakpoints();
const {load: loadUser} = useUser();
const store = useStore();

const {logoUrl} = useProvider();
const {load: loadValues} = useValues();
const {hasEntries} = useInstantPayments()
const {hasEntries: hasAdditionalEntries} = useAdditionalFee()
const {loadAlerts, loadAlertsCount} = useAlerts();

const loading = ref(true);
const navigation = ref([]);
const userNavigation = ref([]);
const route = useRoute();
const userPermissions = ref([]);
const userRole = ref(null);
const versionNumber = ref("null");
const username = ref(null);
const userRoleLabel = ref(null);
const alertsCount = computed(() => store.getters.getAlertsCount);
const alerts = computed(() => JSON.parse(JSON.stringify(store.getters.getAlertsRaw)));
const unreadAlerts = computed(() => alerts.value.filter(alert => !alert.read));
const getAlerts = () => {
  loadAlertsCount()
  // loadAlerts()
}
let intervalId;

onMounted(async () => {
      loading.value = true;

      getAlerts();
      intervalId = setInterval(getAlerts, 300000);


      versionNumber.value = pkg.version;
      const user = await loadUser(true)
      username.value = user.username
      userRoleLabel.value = user.details.role.label
      const providerConfig = store.getters.getProviderConfiguration;
      // console.log("provider config", providerConfig)
      let hasPaymentProvider = false;
      if (providerConfig.billing?.paymentProviderType === 'stripe' && providerConfig.billing?.hasPaymentProvider) {
        // console.log("hasPaymentProvider")
        hasPaymentProvider = true
      }
      userPermissions.value = user?.permissions
      // console.log("permissions", userPermissions.value)
      userRole.value = user.details.role.name;
      let instantPaymentEntries;
      let additionalFeeEntries;
      if (userRole.value !== 'superadmin') {
        instantPaymentEntries = await hasEntries()
        additionalFeeEntries = await hasAdditionalEntries()
      }

      // when creating new entry: add permissions array or customCheck function to show it based on that
      Promise.allSettled([loadValues()]).then(() => {
        let navBar = [
          {
            name: t("navigation-dashboard"),
            routeName: "dashboard",
            icon: "gauge",
            iconStyle: "fas",
          },
          {
            name: t("navigation-customer"),
            routeName: "customer-list",
            parentName: "customer",
            icon: "user",
            iconStyle: "far",
            permissions: ['User'],
            children: [
              {
                name: t("navigation-customer-keyid-media"),
                routeName: "customer-keyid-media-list",
                icon: "cards-blank",
                iconStyle: "fa",
                permissions: ['KeyidMedia'],
              },
              {
                name: t("navigation-customer-cost-center"),
                routeName: "customer-cost-center-list",
                icon: "building",
                iconStyle: "fa",
                permissions: ['CostCenter'],
              },
              {
                name: t("navigation-customer-affiliate"),
                routeName: "customer-affiliate-list",
                icon: "buildings",
                iconStyle: "fa",
                permissions: ['Affiliates'],
              },
              {
                name: t("navigation-customer-payment-account"),
                routeName: "customer-payment-account-list",
                icon: "credit-card",
                iconStyle: "far",
                permissions: ['PaymentAccount'],
              },
              {
                name: t("navigation-customer-notifications"),
                icon: "bell",
                iconStyle: "fas",
                routeName: "customer-notifications",
                permissions: ['Notification'],
              },
              {
                name: t("navigation-customer-settings"),
                routeName: "customer-settings",
                icon: "gear",
                iconStyle: "fas",
                permissions: ['MandantSettingsUser'],
              },
            ],
          },
          {
            name: t("navigation-crm"),
            routeName: "crm-cases-list",
            parentName: "crm",
            icon: "address-book",
            iconStyle: "fas",
            permissions: ['CrmUserCase'],
            children: [
              {
                name: t("navigation-crm-settings"),
                routeName: "crm-settings",
                icon: "gear",
                iconStyle: "fas",
                permissions: ['MandantSettingsCrmUser'],
              },
            ],
          },
          {
            name: t("navigation-booking"),
            routeName: "booking-list",
            parentName: "booking",
            icon: "steering-wheel",
            iconStyle: "fas",
            permissions: ['Booking'],
            children: [
              {
                name: t("navigation-booking-deleted"),
                routeName: "booking-deleted",
                icon: "trash",
                iconStyle: "fa",
                permissions: ['BookingDeleted'],
              },
              {
                name: t("navigation-booking-restrictions"),
                routeName: "booking-restrictions",
                icon: "do-not-enter",
                iconStyle: "fa",
                permissions: ['BookingRestrictionUser', 'BookingRestrictionUsage'],
              },
              {
                name: t("navigation-booking-feedback"),
                routeName: "booking-feedback",
                icon: "thumbs-up",
                iconStyle: "fa",
                permissions: ['BookingFeedback'],
              },
              {
                name: t("navigation-booking-settings"),
                routeName: "booking-settings",
                icon: "gear",
                iconStyle: "fas",
                permissions: ['MandantSettingsBooking'],
              },
            ],
          },
          {
            name: t("navigation-logbook"),
            routeName: "logbook-list",
            parentName: "logbook",
            icon: "book",
            iconStyle: "fas",
            permissions: ['Logbook'],
            children: [
              {
                name: t("navigation-logbook-settings"),
                icon: "gear",
                iconStyle: "fas",
                routeName: "logbook-settings",
                permissions: ['MandantSettingsLogbook'],
              },
            ],
          },
          {
            name: t("navigation-vehicle"),
            routeName: "vehicle-management-list",
            parentName: "vehicle",
            icon: "car",
            iconStyle: "fas",
            permissions: ['Vehicle'],
            children: [
              {
                name: t("navigation-vehicle-map"),
                icon: "map",
                iconStyle: "fas",
                routeName: "vehicle-map",
                permissions: ['VehicleTracking'],
              },

              {
                name: t("navigation-vehicle-models"),
                icon: "cars",
                iconStyle: "fas",
                routeName: "vehicle-models-list",
                permissions: ['VehicleModel'],
              },
              {
                name: t("navigation-vehicle-costs"),
                icon: "money-bill",
                iconStyle: "fas",
                routeName: "vehicle-costs-list",
                permissions: ['CostElement'],
              },
              {
                name: t("navigation-vehicle-telemetry-devices"),
                icon: "sensor",
                iconStyle: "fas",
                routeName: "vehicle-telemetry-devices-list",
                permissions: ['TelemetryHardware'],
              },
              {
                name: t("navigation-vehicle-fleets"),
                icon: "car-bus",
                iconStyle: "fas",
                routeName: "vehicle-fleets-list",
                permissions: ['Fleet'],
              },
              {
                name: t("navigation-vehicle-damage-cases"),
                icon: "car-burst",
                iconStyle: "fas",
                routeName: "vehicle-damage-cases-list",
                permissions: ['DamageCase'],
              },
              {
                name: t("navigation-vehicle-settings"),
                icon: "gear",
                iconStyle: "fas",
                routeName: "vehicle-settings",
                permissions: ['MandantSettingsVehicle'],
              },
            ],
          },

          {
            name: t("navigation-vehicle-files"),
            routeName: "vehicle-files-basic-data",
            parentName: "vehicle-files",
            icon: "file",
            iconStyle: "fas",
            permissions: ['VehicleFile'],
            children: [
              {
                name: t("navigation-vehicle-files-uploads"),
                icon: "file-arrow-up",
                iconStyle: "fas",
                routeName: "vehicle-files-uploads",
                permissions: ['VehicleFileDocument'],
              },
            ]
          },

          {
            name: t("navigation-accounting"),
            routeName: "",
            parentName: "accounting",
            icon: "coins",
            iconStyle: "fas",
            permissions: ['Accounting'],
            children: [
              {
                name: t("navigation-accounting-billing"),
                icon: "file-invoice",
                iconStyle: "fas",
                routeName: "accounting-billing-list",
                permissions: ['BillingCycle'],
              },
              {
                name: t("navigation-accounting-registration-tariffs"),
                icon: "ticket",
                iconStyle: "fas",
                routeName: "accounting-registration-tariffs-list",
                permissions: ['RegistrationTariff'],
              },
              {
                name: t("navigation-accounting-tariffs"),
                icon: "ticket",
                iconStyle: "fas",
                routeName: "accounting-tariffs-list",
                permissions: ['PricingMembership'],
              },
              {
                name: t("navigation-accounting-usage-tariffs"),
                icon: "clock",
                iconStyle: "fas",
                routeName: "accounting-usage-tariffs-list",
                permissions: ['PricingUsage'],
              },
              {
                name: t("navigation-accounting-trip-packages"),
                icon: "boxes-stacked",
                iconStyle: "fas",
                routeName: "accounting-trip-packages-list",
                permissions: ['TripPackage'],
              },
              {
                name: t("navigation-accounting-additional-fee-label"),
                icon: "hand-holding-dollar",
                iconStyle: "fas",
                routeName: "accounting-additional-fee-list",
                permissions: ['AdditionalFee'],
              },
              {
                name: t("navigation-accounting-instant-payments-label"),
                icon: "credit-card",
                iconStyle: "far",
                routeName: "accounting-instant-payments-bookings",
                customCheck: () => {
                  if (!userPermissions?.value['InstantBillingEntriesBooking']?.read &&
                      !userPermissions?.value['InstantBillingEntriesAdditionalFee']?.read &&
                      (userRole.value !== '3' || !hasPaymentProvider || (!additionalFeeEntries && !instantPaymentEntries))) {
                    return false; // don't show it
                  } else {
                    return true // show it
                  }
                }
              },
              {
                name: t("navigation-invoice-details-label"),
                icon: "money-check",
                iconStyle: "far",
                routeName: "accounting-invoice-details-list",
                permissions: ['InvoiceDetail'],
              },
              {
                name: t("navigation-cost-overview"),
                icon: "money-bills",
                iconStyle: "far",
                routeName: "accounting-cost-overview-list",
                customCheck:  () => {
                  if (!providerConfig.general.isCostOverviewAvailable ||
                      (providerConfig.general.isCostOverviewAvailable &&
                          providerConfig.general.enableCostOverviewByCostElement)) {
                    return false;
                  } else {
                    return true
                  }
                }
              },
              {
                name: t("navigation-accounting-settings"),
                icon: "gear",
                iconStyle: "fas",
                routeName: "accounting-settings",
                permissions: ['MandantSettingsAccounting'],
              },
            ],
          },
          {
            name: t("navigation-reporting"),
            routeName: "kpi-list",
            parentName: "kpi-list",
            icon: "square-poll-horizontal",
            iconStyle: "fas",
            permissions: ['Report'],
            children: [
              {
                name: t("navigation-reporting-denied-vehicle-access"),
                icon: "circle-exclamation",
                iconStyle: "fas",
                routeName: "reporting-denied-vehicle-access-list",
                permissions: ['DeniedVehicleAccess'],
              },
              {
                name: t("navigation-vehicle-sentinel"),
                routeName: "sentinel-list",
                icon: "server",
                iconStyle: "fas",
                permissions: ['SentinelData'],
              },
              {
                name: t("navigation-exports"),
                routeName: "exports-list",
                icon: "download",
                iconStyle: "fas",
                permissions: ['Report'],
              },
              {
                name: t("navigation-vehicle-control"),
                icon: "list",
                iconStyle: "fas",
                routeName: "vehicle-control-list",
                permissions: ['VehicleControl'],
              },
            ],
          },
          {
            name: t("navigation-faq"),
            routeName: "faq-topic-list",
            icon: "comments-question",
            iconStyle: "fas",
            permissions: ['Faq'],
            children: [],
          },
          {
            name: t("navigation-provider"),
            parentName: "provider",
            routeName: "provider-list",
            icon: "user-tie",
            iconStyle: "fas",
            permissions: ['Mandant'],
            children: [
              {
                name: t("navigation-provider-oauth"),
                icon: "gear",
                iconStyle: "fas",
                routeName: "provider-oauth",
                permissions: ['OAuthProvider'],
              },
              {
                name: t("navigation-provider-settings"),
                icon: "gear",
                iconStyle: "fas",
                routeName: "provider-settings",
                customCheck: () => user.details.role.name === 'superadmin'
              },
            ],
          },
          {
            name: t("navigation-license-management"),
            routeName: "",
            parentName: "license-management",
            icon: "file-certificate",
            iconStyle: "fas",
            permissions: ['License'],
            children: [
              {
                name: t("navigation-license-license"),
                icon: "file-certificate",
                iconStyle: "fas",
                routeName: "license-management-license-list",
                permissions: ['License'],
              },
              {
                name: t("navigation-license-partners"),
                icon: "handshake",
                iconStyle: "fas",
                routeName: "license-management-partner-list",
                permissions: ['Partner'],
              },
              {
                name: t("navigation-license-products"),
                icon: "puzzle",
                iconStyle: "fas",
                routeName: "license-management-product-list",
                permissions: ['Product'],
              },
              {
                name: t("navigation-license-modules"),
                icon: "puzzle-piece",
                iconStyle: "fas",
                routeName: "license-management-module-list",
                permissions: ['Module'],
              },
              {
                name: t("navigation-license-productTypes"),
                icon: "object-group",
                iconStyle: "fas",
                routeName: "license-management-productType-list",
                permissions: ['ProductType'],
              },
            ]
          },
          {
            name: t("navigation-alerts"),
            parentName: "alerts",
            routeName: "alerts-list",
            icon: "bell",
            iconStyle: "fas",
            permissions: ['Mandant'],
            children: [
              {
                name: t("navigation-alerts-settings"),
                icon: "gear",
                iconStyle: "fas",
                routeName: "alerts-settings",
                permissions: ['MandantSettingsAlerts'],
              }
            ],
          },
          {
            name: t("navigation-settings"),
            routeName: "",
            parentName: "settings",
            icon: "gear",
            iconStyle: "fas",
            permissions: ['Settings'],
            children: [
              {
                name: t("navigation-settings-basic-data"),
                icon: "building",
                iconStyle: "fas",
                routeName: "settings-basic-data-edit",
              },
              {
                name: t("navigation-settings-design"),
                icon: "palette",
                iconStyle: "fas",
                routeName: "settings-design-edit",
              },
              {
                name: t("navigation-settings-third-party"),
                icon: "cloud",
                iconStyle: "fas",
                routeName: "settings-third-party-edit",
              },
              {
                name: t("navigation-settings-messages"),
                icon: "envelope",
                iconStyle: "fas",
                routeName: "settings-messages",
              },
              {
                name: t("navigation-settings-translations"),
                icon: "globe",
                iconStyle: "fas",
                routeName: "settings-translations",
              },
              {
                name: t("navigation-geofences-list"),
                icon: "map",
                iconStyle: "fas",
                routeName: "settings-geofences-list",
              }
            ],
          },
        ];
        let filteredNavBar;
        const checkPermission = (element) => {
          if (element.permissions) {
          if (!element.permissions.some((permission) => userPermissions?.value[permission]?.read === true)) {
            return false
          }
        }
        if (typeof element?.customCheck === 'function') {
          if (element.customCheck() === false) {
            return false
          }
        }
        return true
        }

        filteredNavBar = navBar.filter((elem) => {
          if (checkPermission(elem) === false) {
            return false
          }
          if (elem.children) {
            const filteredChildren = elem.children.filter((child) => {
              if (checkPermission(child) === false) {
                return false
              }
                return child;
            })
            elem.children = filteredChildren;
            if (elem.routeName === '' && !elem.children?.length > 0) {
              return false;
            }
          }
          return elem
        })
        navigation.value = filteredNavBar;

        userNavigation.value = [
          {
            name: t("navigation-logout"),
            routeName: "logout",
          },
        ];
        loading.value = false;
      });
    }
)
;

onUnmounted(() => {
  // Clear the interval when the component is unmounted

  if (intervalId) {
    clearInterval(intervalId);
  }
});

</script>

<style scoped lang="scss">

</style>
