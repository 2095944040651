<template>
  <div
      class="w-min px-2 rounded-full text-xs leading-4 font-semibold whitespace-nowrap"
      :class="dynamicClasses"
  >
    {{ value }}
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  value: {
    required: true,
    type: [String, Number],
  },
  disabled: {
    required: false,
    type: Boolean,
    default: false,
  },
  uppercase: {
    required: false,
    type: Boolean,
    default: false,
  },
  size: {
    required: false,
    type: String,
    default: "",
  },
  type: {
    required: false,
    type: String,
    default: "primary",
  },
  bgColorClass: {
    required: false,
    type: String,
    default: undefined,
  },
  textColorClass: {
    required: false,
    type: String,
    default: "text-white",
  },
});

const dynamicClasses = computed(() => {
  let classes = [];

  switch (props.size) {
    case "small":
      classes.push("h-badge-small py-0.5");
      break;
    default:
      classes.push("h-badge py-1");
      break;
  }

  if (props.disabled) {
    classes.push("bg-gray-400");
  }

  if (props.uppercase) {
    classes.push("uppercase");
  }

  if (props.bgColorClass) {
    classes.push(`text-white ${props.bgColorClass}`);
  } else {
    switch (props.type) {
      case "primary":
        classes.push("text-white bg-primary-500");
        break;
      case "secondary":
        classes.push("text-gray-900 bg-primary-100");
        break;
      case "gray":
        classes.push("text-gray-600 bg-gray-300");
        break;
      case "danger":
        classes.push("text-white bg-danger");
        break;
      case "warning":
        classes.push("text-white bg-warning");
        break;
      case "info":
        classes.push("text-white bg-info");
        break;
      case "success":
        classes.push("text-white bg-success");
        break;
    }
  }

  return classes.join(" ");
});

</script>