<script setup>
import CollapseableComponent from "@/components/TRNC/CollapseableComponent";
import {computed, ref, watch, watchEffect} from "vue";
import { useRoute } from "vue-router";
import {useStore} from "vuex";
defineProps({
  navigation: Array,
});

const store = useStore()
const route = useRoute();
const routeName = ref(route.name);

watchEffect(() => (routeName.value = route.name));
const defaultOpen = (item) => {
  // if (item.name === "Lizenszverwaltung") {
  //
  // console.log("item", item)
  // console.log("routeName", routeName.value)
  // }
  if (item.parentName === "vehicle" && (routeName.value?.startsWith('vehicle-files') || routeName.value?.startsWith('vehicle-control') )) {
    // console.log("routeName", routeName.value)
    return false
  }
  let result = false;
  if (routeName.value?.startsWith(item.parentName)) {
    result = true;
  }
  item.children?.forEach((children) => {
    if (routeName.value === children.routeName) {
      result = true;
    }
  });
  return result;
};

const alerts = computed(() => JSON.parse(JSON.stringify(store.getters.getAlertsRaw)));
const unreadAlertsCount = computed(() => store.getters.getAlertsCount ? JSON.parse(JSON.stringify(store.getters.getAlertsCount)) : 0);
// const unreadAlertsCount = ref(14);
// watch(() => unreadAlertsCount.value, () => {
//   console.log(unreadAlertsCount.value)
// })

const parentClickCount = ref({})
let lastName;

const parentClick = (item) => {
  // console.log("parentClick", item);
  if (!item.children) {
    return emit('closeSidePanel')
  }
  if (lastName !== item.parentName) {
    parentClickCount.value[lastName] = 0;
  }
  if (item.routeName) {
    if (parentClickCount.value[item.parentName]) {
      parentClickCount.value[item.parentName]++
    } else {
      parentClickCount.value[item.parentName] = 1
    }
    if (parentClickCount.value[item.parentName] === 2) {
      emit('closeSidePanel')
    }
    // console.log("count", parentClickCount.value)
  }
  lastName = item.parentName
}

const emit = defineEmits(['closeSidePanel'])
</script>

<template>
  <nav class="flex-1 px-2 pt-4 pb-7 space-y-1" :key="routeName">
    <div v-for="item in navigation" :key="item.name">
      <CollapseableComponent
        :defaultOpen="defaultOpen(item)"
        noChevron
        noHoover
      >
        <template v-slot:header>
<!--          {{routeName}}-->
<!--          {{item.routeName}}-->
<!--          {{item.routeName === 'faq-topic-list' && routeName === 'faq-topic-question-list' ? "true" : "false"}}-->
          <router-link
              @click="parentClick(item)"
            :active-class="`${
              item.routeName ? 'bg-primary-600 text-white' : ''
            }`"
            :to="{ name: item.routeName }"
            class="text-primary-50 hover:bg-primary-600 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md w-full"
          >
            <div class="flex flex-row w-full group">
              <div class="w-10 text-center relative">
                <font-awesome-icon
                  :icon="[item.iconStyle, item.icon]"
                  :class="[
                    item.current
                      ? 'text-white'
                      : 'text-primary-50 group-hover:bg-primary-600 group-hover:text-white',
                    'flex-shrink-0 h-5 w-5',
                  ]"
                  style="width: 16px"
                />
                <div
                    v-if="item.routeName === 'alerts-list' && unreadAlertsCount > 0"
                    class="absolute -top-3 -left-0.5 bg-danger rounded-full w-5 h-5 flex items-center justify-center"
                    style="font-size: 0.75rem;"
                >
                  <span class="text-white">{{ unreadAlertsCount }}</span>
                </div>
              </div>
              <div
                class="text-sm leading-4 font-normal flex flex-col justify-center"
              >
                {{ item.name }}
              </div>
            </div>
          </router-link>
        </template>
        <template v-if="item.children">
          <div
            v-for="item in item.children"
            :key="item.name"
            class="group pl-5 py-0.5"
          >
            <router-link
                @click="emit('closeSidePanel')"
              active-class="bg-primary-600 text-white"
              :to="{ name: item.routeName }"
              class="text-primary-50 group-hover:bg-primary-600 group-hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
            >
              <div class="flex flex-row w-full items-center">
                <div class="w-10 text-center">
                  <font-awesome-icon
                    :icon="[item.iconStyle, item.icon]"
                    :class="[
                      item.current
                        ? 'text-white'
                        : 'text-primary-50 group-hover:bg-primary-600 group-hover:text-white',
                      'flex-shrink-0',
                    ]"
                  />
                </div>
                <div class="text-sm leading-4 font-normal">
                  {{ item.name }}
                </div>
              </div>
            </router-link>
          </div>
        </template>
      </CollapseableComponent>
    </div>
  </nav>
</template>
