<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import IbiolaBadge from "@/components/Items/Badge";
import {useI18n} from "vue-i18n";

const {t} = useI18n();
defineProps({
  title: {
    type: [String, Object],
  },
  defaultState: Boolean,
  noChevron: Boolean,
  noHoover: Boolean,
  setRef: Function,
  badge: Object,
});
defineEmits(["click"]);

const onClose = (close) => {
  close();
};
</script>

<template>
  <Disclosure v-slot="{ open, close }" as="div" :defaultOpen="defaultState" :data-cy="title">
    <DisclosureButton
      :class="`flex w-full flex-row justify-between items-center mb-2 ${
        noHoover ? '' : 'hover:opacity-50'
      }`"
      @click="$emit('click', open, close)"
    >
<!--      <div>-->

      <IbiolaText
        :text="title"
        type="subheading"
        class="hover:text-primary flex flex-row"
        v-if="typeof title === 'string'"
      >
      </IbiolaText>
        <!--      </div>-->
      <slot name="header" v-if="typeof title !== 'string'" />
      <div class=" flex flex-row">

      <IbiolaBadge v-if="badge?.label" :value="badge.label" :type="badge.type"
                   class="justify-self-end mr-6"/>
      <font-awesome-icon
        icon="chevron-down"
        :class="{ 'transform rotate-180': open }"
        v-if="!noChevron"
      />
      </div>
    </DisclosureButton>
    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-out"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <DisclosurePanel>
        <slot :close="close"></slot>
      </DisclosurePanel>
    </transition>
    <button :ref="setRef" v-show="false" @click="() => onClose(close)"></button>
  </Disclosure>
</template>
